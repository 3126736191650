@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --app-height: 100%;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #424242;
  background: #FAFAFA;
  scroll-behavior: smooth;
}

strong {
  font-weight: bold !important;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.Toastify__toast-container {
  width: calc(100% - 32px) !important;
  max-width: 500px;
}

.Toastify__toast-container .Toastify__toast {
  padding: 16px 30px 16px 16px;
  border-radius: 8px;
  border: 1px solid;
  color: #0E0907;
  position: relative;
}

.Toastify__toast-container .Toastify__toast-body {
  padding: 0;
}

.Toastify__toast-container .Toastify__toast-icon {
  width: 24px;
}

.Toastify__toast-container .Toastify__close-button {
  opacity: 1;
  position: absolute;
  top: 16px;
  right: 14px;
}

.Toastify__toast-container .Toastify__toast--success {
  background-color: #CBECE2;
  border-color: #30B28C;
}

.Toastify__toast-container .Toastify__toast--error {
  background-color: #F7D7D5;
  border-color: #E16158;
}

@media (max-width: 640px) {
  .Toastify__toast-container {
    width: calc(100% - 40px) !important;
    left: 20px !important;
    bottom: 80px!important;
    margin-top: 20px;
  }

  .Toastify__toast-container .Toastify__toast {
    margin-top: 10px;
  }
}

#coin_shine > path:first-child {
  animation-name: rotateShine;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  transform-origin: center;
  animation-timing-function: linear;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes rotateShine {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

